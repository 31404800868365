import autosize from "./autosize.js";


const personLabels = {
  full_name: "Imię i nazwisko",
  country: "Kraj zamieszkania",
  street: "Ulica",
  house_no: "Numer domu",
  local_no: "Numer lokalu (nieobowiązkowo)",
  postal_code: "Kod pocztowy",
  city: "Miejscowość",
};
const nonPersonLabels = {
  full_name: "Nazwa inwestora",
  country: "Kraj siedziby",
  street: "Ulica siedziby",
  house_no: "Numer budynku siedziby",
  local_no: "Numer lokalu siedziby (nieobowiązkowo)",
  postal_code: "Kod pocztowy siedziby",
  city: "Miejscowość siedziby",
};
const personFieldsOnly = [
  "first_name",
  "last_name"
];
const nonPersonFieldsOnly = [
  "full_name"
];

var __$tree_inp = null;
var __search_to = false;

const show_or_hide_mailing_fields = function() {
  const show = $(this).is(":checked");
  const mailing = $(this)
    .parents(".flex-form")
    .find(".mailing");
  if (show) {
    mailing.show();
  } else {
    mailing.hide();
    mailing.find('input[type="text"], input[type="email"]').val("");
  }
};

const checked_inputs = function() {
  const $inputs = $(
    ".checkbox input[type='checkbox'], .radio .form-check-input",
  );

  $inputs.each(function() {
    const $input = $(this);

    if ($input.is(":checked")) {
      $input.parent().addClass("checked");
    } else {
      $input.parent().removeClass("checked");
    }
  });
};

const adjust_labels_and_fields = function() {
  const isPerson = this.value === "False" ? false : true;
  const labels = isPerson ? personLabels : nonPersonLabels;
  const specificFieldsToShow = isPerson ? personFieldsOnly : nonPersonFieldsOnly;
  const specificFieldsToHide = isPerson ? nonPersonFieldsOnly : personFieldsOnly;
  $(this)
    .parents(".investor")
    .find("input")
    .each(function() {
      const field = $(this)
        .attr("name")
        .match(/investors-[0-9]+-(.+)/)[1];
      const label = $(this)
        .closest(".form-group")
        .find("label");
      if (field in labels) {
        $(this).attr("placeholder", labels[field]);
        label.text(labels[field]);
      }

      // Adjust fields visibility
      if (specificFieldsToShow.includes(field)) {
        $(this).parent().removeClass('d-none');
      }
      if (specificFieldsToHide.includes(field)) {
        $(this).parent().addClass('d-none');
      }
    });
};

const adjust_lot_partial_related_fields = function() {
  const isLotPartial = this.value === "True" ? true : false;
  if (isLotPartial) {
    $(this).parents(".form-group").next('label.partial-description').removeClass('d-none');
    $(this).parents(".form-group").next('label.partial-description').next('.form-group').children().removeClass('d-none');
  }
  else {
    $(this).parents(".form-group").next('label.partial-description').addClass('d-none');
    $(this).parents(".form-group").next('label.partial-description').next('.form-group').children().addClass('d-none');
  }
};

const set_default_poland = function() {
  if ($(this).val() == '') {
    $(this).val('Polska');
  }
};

const place_labels_in_filled_inputs = function() {
  const input = $(".investors-form").find("input");
  input.each(function() {
    $(this).on("focus", function() {
      $(this)
        .parents(".form-group")
        .addClass("filled-form-group");
    });
    $(this).on("blur", function() {
      if (!$(this).val()) {
        $(this)
          .parents(".form-group")
          .removeClass("filled-form-group");
      }
    });
  });
};

const disable_or_enable_electronic_mailbox = function() {
  const consent_denial_value = this.checked;
  const epuap_text_box = $(this)
    .parents(".ecommunication-form")
    .find(".js-epuap-text-input input");
  const consent_hidden_field = $(this)
    .parents(".ecommunication-form")
    .find(".js-electronic-mailing-consent input");
  const hide_if_no_consent = $(this)
    .parents(".ecommunication-form")
    .find(".hide-if-no-consent");

  if (consent_denial_value) {
    epuap_text_box.prop("disabled", true);
    epuap_text_box.val("");
    consent_hidden_field.val("False");
    hide_if_no_consent.hide();
  } else {
    epuap_text_box.prop("disabled", false);
    consent_hidden_field.val("True");
    hide_if_no_consent.show();
  }
};

const disable_or_enable_sharing_options = function() {
  const sharing_value = this.checked;
  const sharing_options_box = $(this)
    .parents(".sharing-form")
    .find(".sharing-options");

  if (sharing_value) {
    sharing_options_box.removeClass('d-none');
  } else {
    sharing_options_box.addClass('d-none');
  }
};

const disable_or_enable_sharing_email = function() {
  const sharing_value = $(this).val();

  const sharing_email_input = $(this)
    .parents(".sharing-form")
    .find(".sharing-email input");

  if (sharing_value == 'True') {
    sharing_email_input.val('');
  } else {
  }
 
};

const disable_or_enable_public_road_number = function() {
  const public_road_access_value = $(this).val();
  const public_road_access_first_value = $('.form-item :radio').first().val();

  const public_road_number_container = $(this)
    .parents(".form-item")
    .find(".public-road-number");

  const label_indirect_access = $(this)
    .parents(".form-item")
    .find("span.indirect-access");

  const label_direct_access = $(this)
    .parents(".form-item")
    .find("span.direct-access");

  if (public_road_access_value == public_road_access_first_value) {
    public_road_number_container.removeClass('d-none');
    label_direct_access.removeClass('d-none');
    label_indirect_access.addClass('d-none');
  } else {
    public_road_number_container.addClass('d-none');
    label_direct_access.addClass('d-none');
    label_indirect_access.removeClass('d-none');
  }
 
};

const set_sharing_email_radio = function() {
  const sharing_email_radio = $(this)
    .parents(".sharing-form")
    .find(".sharing-email-switch input[type=radio]");
  sharing_email_radio.click()
};

const disable_or_enable_representative_block = function() {
  var enable = false
  $(".is-person :radio").each(function() {
    if ($(this).is(":checked")) {
      if ($(this).val() == 'False') {
        enable = true;
      }
    }
  })
  if (enable) {
    $("#representative-block").parent().removeClass('d-none');
    $("#representative-block").removeClass('d-none');
  }
  else {
    $("#representative-block").addClass('d-none');
  }
}

const sync_consent_with_address = function() {
  const consentHiddenField = $(this)
    .parents(".ecommunication-form")
    .find(".js-electronic-mailing-consent input");
  // check if text box has any value inside
  if ($(this).val()) {
    consentHiddenField.val("True");
  } else {
    consentHiddenField.val("False");
  }
};

const initialize_consent_checkbox_value = function() {
  const ecommunication_form = $(".ecommunication-form");
  if (!ecommunication_form) {
    return;
  }
  ecommunication_form.each(function() {
    const epuapTextInput = $(this).find(".js-epuap-text-input input");
    const consentHiddenFieldValue = $(this)
      .find(".js-electronic-mailing-consent input")
      .val();

    const consentDenialFieldUserInput = $(this).find(
      ".js-electronic_mailing_consent_denial_user_input",
    );

    const userFormCheck = consentDenialFieldUserInput.find(".form-check");
    const userFormCheckInput = userFormCheck.find("input");

    if (consentHiddenFieldValue === "False") {
      userFormCheck.addClass("checked");
      userFormCheckInput.attr("checked");
      epuapTextInput.attr("disabled", "");
    } else if (consentHiddenFieldValue === "True") {
      userFormCheck.removeClass("checked");
      userFormCheckInput.removeAttr("checked");
      epuapTextInput.removeAttr("disabled");
    }
  });
};

var __pkob_dict = {};
if (typeof(__pkob) != 'undefined') {
  for (var i1 in __pkob) {
    const el1 = __pkob[i1];
    __pkob_dict[el1.text] = el1.id;
    for (var i1 in el1.children) {
      const el2 = el1.children[i1];
      __pkob_dict[el2.text] = el2.id;
      for (var i2 in el2.children) {
        const el3 = el2.children[i2];
        __pkob_dict[el3.text] = el3.id;
      }
    }
  }
}

var __pkob_perc = {};

const __init_pkob_perc = function (data) {
  $('#jstree .pkob-perc').remove();
  const selected = $('#jstree').jstree("get_selected");
  if (data.action == 'select_node') {
    if (selected.length == 1) {
      __pkob_perc[data.node.id] = 100;
    } else {
      var allequal = true;
      var first = null;
      for (var i in selected) {
        var id = data.instance.get_node(selected[i]).id;      
        if (id == data.node.id)
          continue;
        if (first == null) {
          if (id in __pkob_perc)
            first = __pkob_perc[id];
        } else {
          if ((id in __pkob_perc) && (__pkob_perc[id] != first)) {
            allequal = false;
            break;
          }        
        }
      }
      if (allequal) {
        var sum = 0;
        var cnt = 1;
        for (var i in selected) {
          var id = data.instance.get_node(selected[i]).id;    
          if (id == data.node.id)
            continue;        
          sum += __pkob_perc[id];        
          cnt++;
        }
        if ((sum == 99) || (sum == 100)) {
          var v = Math.round(sum / cnt);
          __pkob_perc[data.node.id] = v;
          for (var i in selected) {
            var id = data.instance.get_node(selected[i]).id;    
            __pkob_perc[id] = v;
          }
        }
      }
    }
  }
  var ids = []; 
  var sum = 0;
  for (var i in selected) {
    ids.push(data.instance.get_node(selected[i]).id);
  }
  for (var i in __pkob_perc) {
    if (ids.includes(i)) {
      sum += __pkob_perc[i];
    } else {
      delete __pkob_perc[i];
    }
  }
  if (sum > 100)
    sum = 100;
  for (var i in selected) {
    const id = data.instance.get_node(selected[i]).id;        
    if (!(id in __pkob_perc)) {
      __pkob_perc[id] = 100 - sum;
    }
    $('#' + id + ' .jstree-anchor').append('<span class="pkob-perc"><input id="pp_' + id + '" type="number" min="1" max="100" step="1" value="' + __pkob_perc[id] + '" style="width: 3.5em; height: 1.4em; margin-left: 0.5em; margin-right: 0.1em" onkeypress="if (!/[0-9\/]+/.test(event.key)) { event.preventDefault(); }" />%</span>');
    $('#pp_' + id).click(function (e) { e.stopPropagation(); });
    $('#pp_' + id).change(function () {
      const id = this.id.replace('pp_', '');
      __pkob_perc[id] = $(this).val();
      __build_pkob_texts(data);
    });  
  } 
}

const __build_pkob_texts = function (data) {
  var texts = [];
  var sum = 0;
  const selected = $('#jstree').jstree("get_selected");
  for (var i in selected) {
    const node = data.instance.get_node(selected[i]);
    const id = node.id;    
    var text = node.text;    
    if (id in __pkob_perc) {
      const nr = parseInt(__pkob_perc[id]);
      if (nr) {
        text += ' (' + nr + '%)';
        sum += nr;
      }
    }
    texts.push(text);
  }
  __$tree_inp.val(texts.join(', '));
  autosize.update(__$tree_inp);
  if ((selected.length) && (sum != 100)) {
    $('#closePkobModal').prop('disabled', true);
    $('#pkobInfo').text('Suma procentowych udziałów musi być równa 100%. Obecnie wynosi ' + sum + '%.');
  } else {
    $('#closePkobModal').prop('disabled', false);
    $('#pkobInfo').text('');
  }
}


const check_pkob_residential = function () {
  $('.needs-tree .form-group textarea').each(function () {
    const text = $(this).val();
    //const $parent = $(this).parent().parent().parent().parent();
    const $parent = $(this).parent().parent().parent();
    if (text.includes('111') || text.includes('112') || text.includes('113')) {
      $parent.next().find('.form-group').show();      
    } else {      
      $parent.next().find('.form-group').hide();
    }
  });  
}

var __pkob_loaded = false;

const init_pkob_tree = function ($element) {
  autosize($element.find('.needs-tree .form-group textarea')); 
  $element.find('.needs-tree .form-group textarea').attr('readonly', 'readonly').css('background-color', '#FFF').click(function () {
    __$tree_inp = $(this);
    try {
      $('#jstree').off('loaded.jstree');
      $('#jstree').off('click', '.jstree-anchor');
      $('#jstree').off("open_node.jstree");
      $('#jstree').off("close_node.jstree");
      $('#jstree').jstree("destroy");
    } catch (e) {}
    $('#jstree').jstree({
      "plugins" : [ "search", "checkbox" ],
      "checkbox" : {
        "three_state": false
      },
      "core" : {
        "themes": {
          "dots": false
        },
        'data': __pkob
      }}).on("changed.jstree", function (e, data) {
        if (__pkob_loaded)
          __init_pkob_perc(data);
        __build_pkob_texts(data);
        check_pkob_residential();
      });
      $('#jstree').on('loaded.jstree', function (e) {
        __pkob_loaded = false;
        const selected = __$tree_inp.val().split(',');
        for (var i in selected) {
          const tarr = selected[i].trim().split(' (');
          const t = tarr[0];          
          if (t in __pkob_dict) {
            if (tarr.length > 1) {
              const perc = tarr[1].replace('%)', '');
              __pkob_perc[__pkob_dict[t]] = perc;
            }
            $("#jstree").jstree('select_node', __pkob_dict[t]);
          }
        }
        $('#jstree .jstree-open > .jstree-anchor > .jstree-themeicon').removeClass('fa-folder-closed').addClass('fa-folder-open');                
        __pkob_loaded = true;
      });

    $('#jstree').on('click', '.jstree-anchor', function (e) {
        $(this).jstree(true).toggle_node(e.target);
      });  
    $('#jstree').on("open_node.jstree", function (e, data) {
        $('#' + data.node.id + ' > .jstree-anchor > .jstree-themeicon').removeClass('fa-folder-closed').addClass('fa-folder-open');
        __init_pkob_perc(data);
      });
    $('#jstree').on("close_node.jstree", function (e, data) {
        $('#' + data.node.id + ' > .jstree-anchor > .jstree-themeicon').removeClass('fa-folder-open').addClass('fa-folder-closed');
        __init_pkob_perc(data);
      });
    $('#tree-search').keyup(function () {
        if (__search_to) { clearTimeout(__search_to); }
        __search_to = setTimeout(function () {
          var v = $('#tree-search').val();
          if (v.length)
            $('#jstree').jstree(true).search(v);
          else
            $("#jstree").jstree("clear_search");          
        }, 250);      
      });
    $('#tree-search').change(function () {       
          var v = $('#tree-search').val();
          if (v.length == 0)
            $("#jstree").jstree("clear_search");          
      });         
    $('#pkobModal').modal({ backdrop: 'static', keyboard: false }, 'show');        
  });
}

const handle_investors_fields = element => {
  const $element = element ? $(element) : null;
  const $address_different_checkboxes = $element
    ? $element.find(".mailing-address-different input")
    : $(".mailing-address-different input");

  $address_different_checkboxes.change(show_or_hide_mailing_fields);
  $address_different_checkboxes.change();

  const $inputs = $element
    ? $element.find(
        ".checkbox input[type='checkbox'], .radio .form-check-input",
      )
    : $(".checkbox input[type='checkbox'], .radio .form-check-input");

  $inputs.change(function() {
    checked_inputs();
  });
  $inputs.change();

  if ($(".is-person :radio").length)
  {
    const $isPersonFields = $element
      ? $element.find(".is-person :radio")
      : $(".is-person :radio");
    $isPersonFields.change(adjust_labels_and_fields);
    $isPersonFields.change(disable_or_enable_representative_block);
    $isPersonFields.each(function() {
      if ($(this).is(':checked')) {
        $(this).change();
      }
    });
  }

  $(".sharing-switch input").each(function() {
    $(this).change(disable_or_enable_sharing_options);
    if ($(this).is(':checked')) {
      $(this).change();
    }
  });

  $(".sharing-email-switch input").each(function() {
    $(this).change(disable_or_enable_sharing_email);
    if ($(this).is(':checked')) {
      $(this).change();
    }
  });

  $(".sharing-options input[type=email]").each(function() {
    $(this).focus(set_sharing_email_radio);
  });

  $(function() {
    place_labels_in_filled_inputs();
  });

  const $electronicCorrespondenceConsentDenialHiddenFields = $element
    ? $element.find(".js-electronic_mailing_consent_denial_user_input input")
    : $(".js-electronic_mailing_consent_denial_user_input input");
  $electronicCorrespondenceConsentDenialHiddenFields.change(
    disable_or_enable_electronic_mailbox,
  ).change();

  const $epuapTextBoxFields = $element
    ? $element.find(".js-epuap-text-input input")
    : $(".js-epuap-text-input input");

  $epuapTextBoxFields.change(sync_consent_with_address);

  initialize_consent_checkbox_value();

  const $mailingEpuapSwitch = $element
    ? $element.find(".js-mailing-epuap input")
    : $(".js-mailing-epuap input");
  $mailingEpuapSwitch.change(function () {
    const $el = $(this).parent().parent().parent().parent().find('.mailing-epuap');
    if ($(this).is(':checked')) {
      $el.css({ 'overflow': 'auto', 'height': 'auto' });
    } else {
      $el.css({ 'overflow': 'hidden', 'height': '0px' });
      $el.find('input').val('');
    }
  }).change();

  const postalCodeElements = element
    ? element.getElementsByClassName("js-postal-code")
    : document.getElementsByClassName("js-postal-code");

  const postalCodeFields = [...postalCodeElements]
    .map(element => element.getElementsByTagName("input").item(0))
    .filter(val => val !== null);

  for (const field of postalCodeFields) {
    field.addEventListener("input", function(event) {
      if (
        event.data && // distinguish entering new char from pressing backspace
        event.target.value &&
        event.target.value.length === 2
      ) {
	if ($(this).parent().hasClass('mailing')) {
          var country = $(this).parents(".investors-form").find(".mailing.country :input").val();
          if (country.toLowerCase().replace(/[^a-z]/g, '') == 'polska') {
            event.target.value = event.target.value + "-";
          }
        }
	else {
          var country = $(this).parents(".investors-form").find(".country :input").val();
          if (country.toLowerCase().replace(/[^a-z]/g, '') == 'polska') {
            event.target.value = event.target.value + "-";
          }
        }
      }
    });
  }

  $(".country :input").each(function() {
    if ($(this).val() == '') {
      $(this).val('Polska');
    }
    $(this).change(set_default_poland);
  });

  if ($("input[name=role]").length)
  {
    var role = $("input[name=role]").val();
    if (role == 'representative' || role == 'investor') {
      $(".is-person:first").addClass('disabled');
    }
  }

  if ($("input[name=final_checks]").length)
  {
    if ($("input[name=final_checks]").val() == 1) {
      $(".investor-final-checks").each(function() {
        $(this).val('1');
      });
      $("input[name$=-is_person]").prop('disabled', false);
      $("#investors-form").submit();
    }
    else {
      $(".investor-final-checks").each(function() {
        $(this).val('0');
      });
    }
  }

  if ($(".lot-partial :radio").length)
  {
    const $isPatialLotFields = $element
      ? $element.find(".lot-partial :radio")
      : $(".lot-partial :radio");
    $isPatialLotFields.change(adjust_lot_partial_related_fields);
    $isPatialLotFields.each(function() {
      if ($(this).is(':checked')) {
        $(this).change();
      }
    });
  }

  if ($(".public-road-number").length)
  {
    const $field = $element
      ? $element.find(":radio")
      : $(":radio");
    $field.change(disable_or_enable_public_road_number);
    $field.each(function() {
      if ($(this).is(':checked')) {
        $(this).change();
      }
    });
  }

  $('#stepper a.nav-link').click(function() {
    var input = $("<input>").attr("type", "hidden").attr("name", "next_step").val($(this).attr('step'));
    var form = $(this).parents('form');
    form.append($(input));
    form.submit();
    return false;
  });

  $(document).ready(function() {
    $('.multiple-select2').select2();
    
    if (typeof(_inv_objs) != 'undefined') {
      for (var i in _inv_objs) {
        if (!$('#id_investors-' + i + '-is_person').length)
          continue;
        if ($('#id_investors-' + i + '-is_person input:checked').length)
          continue;
        var $el = $('#id_investors-' + i + '-is_person input[value=' + _inv_objs[i] + ']');
        $el.prop('checked', true);
        $el.parent().addClass('checked');
        if (_inv_objs[i] == 'False') {
          $('#id_investors-' + i + '-full_name').parent().removeClass('d-none');
          $('#id_investors-' + i + '-first_name').parent().addClass('d-none');      
          $('#id_investors-' + i + '-last_name').parent().addClass('d-none');
        } else {
          $('#id_investors-' + i + '-full_name').parent().addClass('d-none');  
          $('#id_investors-' + i + '-first_name').parent().removeClass('d-none');     
          $('#id_investors-' + i + '-last_name').parent().removeClass('d-none');
        }
      }
    }
    
    init_pkob_tree($('body'));    
    
    $('.has-address input.form-check-input').change(function () {
      var $el = $(this);
      if ($el.is(':checked')) {
        $el.parent().parent().parent().parent().parent().parent().find('.address-data').show();
      } else {
        $el.parent().parent().parent().parent().parent().parent().find('.address-data').hide();
      }
    }).change();
  });

  if ($('.form-group.is-invalid').length)
  {
    $('html, body').animate({
      scrollTop: $('.form-group.is-invalid').not('.d-none').first().offset().top - 50
    }, 800);
  }
  
  if ($element) {
    init_pkob_tree($element);
  }
  
  check_pkob_residential();
  
  $('#closePkobModal').click(function () {
    $('#pkobModal').modal('hide');    
  });  
};

export default handle_investors_fields;
