import handleInvestorsCopyField from "./handle_investors_copy.js";
import traverseAllChildNodes from "./traverseAllChildNodes.js";

const formRegex = RegExp(/-\d+-/g);

function getAttributes(node) {
  const attributeNodes = node.attributes;
  const length = attributeNodes.length;
  const attrs = {};
  for (let i = 0; i < length; i++) {
    attrs[attributeNodes[i].name] = attributeNodes[i].value;
  }
  return attrs;
}

function replaceIndexAttributes(node, index) {
  if (node.attributes) {
    if (node.getAttribute("data-item-index")) {
      node.setAttribute("data-item-index", index);
    }
    const nodeAttributes = getAttributes(node);
    Object.entries(nodeAttributes).forEach(entry => {
      const [attribute, value] = entry;
      if (value.search(formRegex)) {
        const newValue = value.replace(formRegex, `-${index}-`);
        node.setAttribute(attribute, newValue);
      }
    });
  }
}

function reindexFormItems() {
  const $formItems = $(".form-item");
  $formItems.each(function(el) {
    traverseAllChildNodes(this, node => replaceIndexAttributes(node, el));
  });
  $("input[name$=-TOTAL_FORMS]").val($formItems.length);
}


// script handles deletion of form entries
function deleteFormEntry(event) {  
  const $parentFormElementsLength = $(".form-item:visible").length;
  const $refreshButton = $("#refresh-form-button");
  var $parentFormElement = $(`#${event.target.dataset.formId}`);

  const $parentFormElementIdInputFieldValue = $parentFormElement
    .find("input[name$=-id]")
    .attr("value");

  if ($parentFormElement.parent().hasClass('form-item') && $parentFormElement.parent().hasClass('card')) {
    $parentFormElement = $parentFormElement.parent();
  }

  if ($parentFormElementsLength > 1) {
    if ($parentFormElementIdInputFieldValue) {
      // if form exists in database
      $parentFormElement.hide();
      const $formFieldDelete = $parentFormElement.find(
        ".js-form-field-delete input",
      );
      $formFieldDelete.trigger("click");
    } else {
      // if form doesn't exists in database
      $parentFormElement.remove();
    }
  } else if ($refreshButton.length) {
    const $formFieldDelete = $parentFormElement.find(
      ".js-form-field-delete input",
    );
    $formFieldDelete.trigger("click");
    
    if ($('#other-atts').length == 0)
      $refreshButton.trigger("click");
  } else {
    $parentFormElement.find("input").each(function() {
      const $el = $(this);
      const $elType = $el.attr("type");
      if (
        $elType === "hidden" ||
        $elType === "checkbox" ||
        $elType === "radio"
      ) {
        return;
      }
      $el.val(null);
    });
  }  
  
  reindexFormItems();
  handleInvestorsCopyField();
  
  if ($('.form-item__attachment').length > 1) {
    $('.form-item__attachment .js-delete-file-button').show();
  } else {
    $('.form-item__attachment .js-delete-file-button').hide();
  }
  
  if (typeof(__updateCountHook) == 'function') {
    __updateCountHook();
  }
}



var __lastDeleteEvent = null;

function deleteFormEntryAsk(event) {
  var all_empty = true;
  const $parentFormElement = $(`#${event.target.dataset.formId}`);
  $parentFormElement.find('input[type="text"], input[type="search"]').each(function () {
    if ($(this).val().length)
      all_empty = false;
  });
  if (all_empty) {
    deleteFormEntry(event);
  } else {    
    __lastDeleteEvent = event;
    $('#deleteLotModalConfirm').off('click');
    $('#deleteLotModalConfirm').on('click', function () {
      deleteFormEntryConfirm();
    });
    $('#deleteLotModal').modal('show');
  }
}

function deleteFormEntryConfirm() {
  $('#deleteLotModal').modal('hide');
  if (__lastDeleteEvent) {
    deleteFormEntry(__lastDeleteEvent);
    __lastDeleteEvent = null;
  }
}


const initializeDeleteButtonsFunction = function() {
  const deleteButtons = document.querySelectorAll(".js-delete-form-button");
  if (!deleteButtons.length) {
    return;
  }

  deleteButtons.forEach(button => {
    if ($(button).hasClass('needs-confirmation'))
      button.addEventListener("click", deleteFormEntryAsk);
    else
      button.addEventListener("click", deleteFormEntry);
  });
};

export { initializeDeleteButtonsFunction, deleteFormEntry, deleteFormEntryAsk };
